import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import React, { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import { AuthProvider } from './context/AuthContext';
import AuthValidator from './components/utilities/AuthValidator';
import RouteManager from './routes/index';
import Auth from './routes/auth';
import './static/css/style.css';

const NotFound = lazy(() => import('./container/pages/404'));
const { themeColor, rtl, topMenu, mainTemplate: mainContent } = config;

function ProviderConfig() {
  return (
    <AuthProvider>
      <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
        <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Auth />} />
              <Route element={<AuthValidator />}>
                <Route path="/app/*" element={<ProtectedRoute path="/*" Component={RouteManager} />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </ConfigProvider>
    </AuthProvider>
  );
}

function App() {
  return <ProviderConfig />;
}

export default App;
