import { themeColor } from './theme/themeVariables';

const config = {
  topMenu: false,
  rtl: false,
  mainTemplate: 'lightMode',
  layoutMode: 'lightMode',
  themeColor,
  footerUrl: 'https://www.luisgabrieltorresvidal.com/',
  footerNombre: 'Luis Gabriel Torres VIdal',
  logoWhite: '/logo_white.svg',
  logoDark: '/logo_dark.svg',
};

export default config;
