import { Spin } from 'antd';
import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import withAdminLayout from '../layout/withAdminLayout';
import { useAuth } from '../context/AuthContext';

const EmpresasPage = lazy(() => import('../container/pages/empresas/Empresas'));
const BlankPage = lazy(() => import('../container/pages/BlankPage'));
const DashboardPage = lazy(() => import('../container/pages/Dashboard'));
const NotFound = lazy(() => import('../container/pages/404'));

const RouteManager = React.memo(() => {
  const profile = '1';
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }>
      <Routes>
        <Route path="/" element={<Navigate to="/app/dashboar3ss" />} />
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="empresas" element={<EmpresasPage />} />
        {profile === 1 && <Route path="starter" element={<BlankPage empresa="luis" />} />}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

// export default withAdminLayout(RouteManager);
// export default withAdminLayout((props) => <RouteManager {...props} />);
const RouteManagerWithLayout = withAdminLayout(RouteManager); // Envuelve RouteManager con withAdminLayout

function App() {
  const { user } = useAuth(); // Obtiene el usuario desde el contexto de autenticación
  return <RouteManagerWithLayout user={user} />; // Pasa el usuario como una propiedad a RouteManager
}

export default App;
