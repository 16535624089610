import React, { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AuthLayout from '../container/pages/authentication/Index';
import { useAuth } from '../context/AuthContext';

const Login = lazy(() => import('../container/pages/authentication/SignIn'));

const FrontendRoutes = React.memo(() => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/app/dashboard" />;
  }
  return (
    <Routes>
      <Route index element={<Login />} />
    </Routes>
  );
});

export default AuthLayout(FrontendRoutes);
