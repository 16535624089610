import {
  UilCreateDashboard,
  UilEllipsisV,
  UilWindow,
  UilFilePlusAlt,
  UilHome,
  UilUsersAlt,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';

import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import config from '../config/config';

function MenuItems({ toggleCollapsed, perfil }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = config;

  const path = '/app';
  const pathName = window.location.pathname;
  const pathArray = pathName && pathName !== '/' ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  const starterMenu =
    perfil === 1 &&
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/starter`}>
        {t('agregar')}
      </NavLink>,
      'starter',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/starter`}>
          <UilFilePlusAlt />
        </NavLink>
      ),
    );

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t('tablero')}
      </NavLink>,
      'dashboard',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilCreateDashboard />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/empresas`}>
        Empresas
      </NavLink>,
      'empresas',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/empresas`}>
          <UilHome />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/usuarios`}>
        usuarios
      </NavLink>,
      'usuarios',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/usuarios`}>
          <UilUsersAlt />
        </NavLink>
      ),
    ),
    getItem(t('demostraciones'), 'ventana', !topMenu && <UilWindow />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/demo-1`}>
          {t('demostracion')} 1
        </NavLink>,
        'demo-1',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/demo-2`}>
          {t('demostracion')} 2
        </NavLink>,
        'demo-2',
        null,
      ),
    ]),
    starterMenu,
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
  perfil: propTypes.string,
};

export default MenuItems;
