import propTypes from 'prop-types';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

function ProtectedRoute({ Component, path }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? (
    <Routes>
      {' '}
      <Route element={<Component />} path={path} />{' '}
    </Routes>
  ) : (
    <Routes>
      {' '}
      <Route path="/app" element={<Navigate to="/" />} />
    </Routes>
  );
}

ProtectedRoute.propTypes = {
  Component: propTypes.elementType.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
