import { UilSetting, UilSignout, UilKeySkeleton, UilAngleDown } from '@iconscout/react-unicons';

import { Avatar, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { supabase } from '../config/api/client';
import { InfoWraper, UserDropDwon, FlagDropDwon } from '../style/style';
import { Popover } from '../components/popup/popup';
import { useAuth } from '../context/AuthContext';
import { Modal } from '../components/modals/modal';
import { Button } from '../components/buttons/buttons';

const MenuHeader = React.memo(({ usuario }) => {
  const { logout } = useAuth();
  const [state, setState] = useState({
    flag: usuario.user_metadata.idioma,
  });

  const { t, i18n } = useTranslation();
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();
    document.body.classList.remove('dark-mode');
    logout();
  };

  const [form] = Form.useForm();

  const showModal = () => {
    setState({
      ...state,
      visible: true,
      collapsed: false,
    });
    form.resetFields();
  };

  const handleCancel = () => {
    setState({
      ...state,
      visible: false,
    });
  };

  const handlePassword = async (values) => {
    try {
      await supabase.auth.updateUser({
        password: values.password,
      });
      message.success('Contraseña actualizada', 1);
    } catch (error) {
      message.error('Error al actualizar contraseña', 1);
    }

    handleCancel();
  };

  const userContent = (
    <>
      <UserDropDwon minWidth="180px">
        <div className="user-dropdwon">
          <ul className="user-dropdwon__links">
            <li>
              <Link onClick={showModal}>
                <UilKeySkeleton /> {t('password')}
              </Link>
            </li>
            <li>
              <Link to="#">
                <UilSetting /> {t('ajustes')}
              </Link>
            </li>
          </ul>
          <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
            <UilSignout /> {t('desconectar')}
          </Link>
        </div>
      </UserDropDwon>
      <Modal
        title="Cambiar contraseña"
        className="template-modal"
        type="primary"
        visible={state.visible}
        footer={null}
        width={590}
        maskClosable={false}
        onCancel={handleCancel}>
        <div className="template-modal-inner">
          <>
            <Form form={form} name="cambiopassword" onFinish={handlePassword}>
              <Form.Item
                rules={[
                  { required: true, message: 'Contraseña obligatoria' },
                  { min: 6, message: 'Mínimo 6 caracteres' },
                ]}
                name="password"
                autoComplete="off">
                <Input.Password placeholder="Nueva contraseña" />
              </Form.Item>
              <div className="modal-actions">
                <Button htmlType="submit" type="info" size="default">
                  Ok
                </Button>
                <Button type="danger" size="default" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </Form>
          </>
        </div>
      </Modal>
    </>
  );

  const onFlagChangeHandle = async (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
    try {
      await supabase.auth.updateUser({
        data: {
          idioma: value,
        },
      });
    } catch (error) {
      console.error('Error del servidor', error);
    }
  };

  const country = (
    <FlagDropDwon>
      <Link onClick={(e) => onFlagChangeHandle('esp', e)} to="#">
        <img src={require('../static/img/flag/esp.png')} alt="" />
        <span>Spanish</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('en', e)} to="#">
        <img src={require('../static/img/flag/en.png')} alt="" />
        <span>English</span>
      </Link>
    </FlagDropDwon>
  );

  return (
    <InfoWraper>
      <div className="nav-actions__item nav-actions__language">
        <Popover placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="nav-action-link">
            <img src={require(`../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Popover>
      </div>
      <div className="nav-actions__item nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="nav-action-link">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
            <span className="nav-actions__author--name">
              <strong>{usuario.user_metadata.nombre}</strong>
            </span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

MenuHeader.propTypes = {
  usuario: propTypes.object.isRequired, // Define el tipo de la prop userArray como array
};

export default MenuHeader;
