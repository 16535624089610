import { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { supabase } from '../config/api/client';

export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AutProvider');
  }
  return context;
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const signin = async (userData) => {
    try {
      message.destroy();

      const { data, error } = await supabase.auth.signInWithPassword({
        email: userData.email,
        password: userData.password,
      });

      if (error) {
        setIsAuthenticated(false);
        message.error('Error al iniciar sesión:', 3);
        return false;
      }
      setUser(data.session.user);
      setIsAuthenticated(true);
      message.success('Inicio de sesión exitoso', 1);
      return true;
    } catch (error) {
      message.error('Acceso no permitido al servidor', 1);
      return false;
    }
  };

  const logout = async () => {
    try {
      await supabase.auth.signOut();
      setIsAuthenticated(false);
      setUser(null);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (!session) {
        setIsAuthenticated(false);
        setUser(null);
      } else {
        setIsAuthenticated(true);
        setUser(session.user);
      }
    });
  }, []);

  const authContextValue = useMemo(
    () => ({
      signin,
      logout,
      user,
      isAuthenticated,
    }),
    [signin, logout, isAuthenticated],
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
