import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FooterStyle } from '../style/style';
import config from '../config/config';

function obtenerAnioActual() {
  return new Date().getFullYear();
}

function Footer() {
  const { t } = useTranslation();
  const { footerUrl, footerNombre } = config;
  return (
    <FooterStyle className="admin-footer">
      <Row>
        <Col md={12} xs={24}>
          <span className="admin-footer__copyright">
            copyright © {obtenerAnioActual()} <Link to={footerUrl}>{footerNombre}</Link>
          </span>
        </Col>
        <Col md={12} xs={24}>
          <div className="admin-footer__links">
            <NavLink to="#">{t('acerca')}</NavLink>
            <NavLink to="#">{t('equipo')}</NavLink>
            <NavLink to="#">{t('contacto')}</NavLink>
          </div>
        </Col>
      </Row>
    </FooterStyle>
  );
}

export default Footer;
