import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ContentPopup, PopoverStyle, Title } from '../../style/style';
import './style.css';

function Popover(props) {
  const { content, placement, title, action, children } = props;
  // const content1 = <ContentPopup>{content}</ContentPopup>;
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };
  const content1 = (
    <ContentPopup onClick={() => setPopoverVisible(false)}>
      {}
      {content}
    </ContentPopup>
  );
  return (
    <PopoverStyle
      placement={placement}
      title={title && <Title>{title}</Title>}
      content={content1}
      open={popoverVisible}
      onOpenChange={handleVisibleChange}
      trigger={action}>
      {children}
    </PopoverStyle>
  );
}

Popover.defaultProps = {
  action: 'hover',
  placement: 'bottom',
};

Popover.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export { Popover };
